// @ts-check
import React from "react"
import styled from "@emotion/styled"

import Card, { DoubleCard, CardTitle } from "../card"
import DemoContext from "../../contexts/demo"
import { BigPillButton } from "../button"
import { typographyComponents } from "../typography"
import { List, ListItem } from "../list"

const Card2Body = styled(typographyComponents.bodyLarge)`
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const CardsFooter = () => {
  const { open } = React.useContext(DemoContext)
  return (
    <DoubleCard>
      <Card variant="primary" noMinHeight>
        <CardTitle as="h3" variant="h3">
          Mavenoid can <br /> handle it
        </CardTitle>

        <List>
          <ListItem>Self-service</ListItem>
          <ListItem>Troubleshooting</ListItem>
          <ListItem>Live Chat & Video </ListItem>
          <ListItem>Setup & Installation</ListItem>
          <ListItem>FAQs</ListItem>
        </List>
      </Card>
      <Card variant="light" noMinHeight>
        <CardTitle as="h3" variant="h3">
          Automate your tech support now
        </CardTitle>

        <Card2Body as="span">
          Get started with your first virtual expert in less than 20 minutes.
        </Card2Body>

        <BigPillButton onClick={open}>Get a demo</BigPillButton>
      </Card>
    </DoubleCard>
  )
}

export default CardsFooter
