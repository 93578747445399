export const embeddedConfig =
  process.env.NODE_ENV === "development"
    ? {
        apiKey: "oiz98fyg7yvjkl98324uh46bjka73kbe",
        url: "http://localhost:3000/embedded/embedded.js",
        productId: "1902638",
      }
    : {
        apiKey: "oiz98fyg7yvjkl98324uh46bjka73kbe",
        url: "https://open.mavenoid.com/embedded/embedded.js",
        productId: "2892232", // Dishwasher on open
      }

if (typeof window !== "undefined") {
  window.mavenoid = window.mavenoid || []
  window.mavenoid.config = {
    apiKey: embeddedConfig.apiKey,
    noIframe: false,
  }
}

const installMavenoidTS = () => {
  // Test if `window.mavenoid` is not longer an array - in that
  // case don't re-download the embedded script.
  // (the embedded )
  if (!Array.isArray(window.mavenoid)) return
  const script = document.createElement("script")
  script.async = true
  script.src = embeddedConfig.url
  const x = document.getElementsByTagName("script")[0]
  x.parentNode.insertBefore(script, x)
}

export default installMavenoidTS
