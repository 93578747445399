// @ts-check
import React from "react"

/**
 * Activates a given trigger once the middle of the viewport reaches the top of the returned Ref position
 * @param {() => void} handler
 * @returns {React.MutableRefObject<Element>} A ref to attach to the element to track
 */
const useScrollTrigger = handler => {
  /** @type {React.MutableRefObject<Element>} */
  const ref = React.useRef(null)

  React.useLayoutEffect(() => {
    if (!ref.current) return
    const position =
      ref.current.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top

    const listener = () => {
      const scroll =
        document.body.scrollTop || document.documentElement.scrollTop

      if (scroll + window.innerHeight * 0.7 > position) {
        document.removeEventListener("scroll", listener)
        requestAnimationFrame(handler)
      }
    }
    document.addEventListener("scroll", listener)

    const timeout = setTimeout(() => {
      if (
        document.documentElement.scrollTop + window.innerHeight / 2 >
        position
      ) {
        handler()
        return
      }
    }, 50)
    return () => {
      document.removeEventListener("scroll", listener)
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ref
}

export default useScrollTrigger
