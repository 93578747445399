import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardProductHero from "../components/cards/cardProductHero"
import CardTroubleshoot from "../components/cards/cardTroubleshoot"
import CardModels from "../components/cards/cardModels"
import CardAnalytics from "../components/cards/cardAnalytics"
import CardIntegrate from "../components/cards/cardIntegrate"
import CardsFooter from "../components/cards/cardsFooter"
import CardProductLiveSupport from "../components/cards/cardProductLiveSupport"
import CardSeparator from "../components/cardSeparator"

const ProductPage = () => (
  <Layout bg="dark" activeTab="product">
    <SEO
      title="Product"
      meta={[
        {
          property: "og:image",
          content: require("../images/product/landing-product-mobile.jpg"),
        },
      ]}
    />
    <CardProductHero />
    <CardTroubleshoot />
    <CardProductLiveSupport />
    <CardModels />
    <CardSeparator variant="lightpurple" />
    <CardAnalytics />
    <CardSeparator variant="lightpurple" />
    <CardIntegrate />
    <CardsFooter />
  </Layout>
)

export default ProductPage
