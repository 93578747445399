import React from "react"
import styled from "@emotion/styled"

import {
  CardWrapper,
  CardContentWrapper,
  CardTitle,
  CardCaption,
} from "../card"
import { makeFontFamily, typographyComponents } from "../typography"
import Button from "../button"
import colors from "../../styles/colors"
import { borderRadius } from "../../styles/constants"
import useScrollTrigger from "../../hooks/useScrollTrigger"
import installMavenoidTS, { embeddedConfig } from "../../utils/mavenoidts"
import { trackEvent } from "../../utils/trackEvent"

const Wrapper = styled(CardWrapper)`
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const CopyTitle = styled(typographyComponents.caption)`
  color: ${colors.primary};
`
const CopyP = styled(typographyComponents.caption)`
  margin-top: 0;
  margin-bottom: 20px;
`

const CardLeft = styled(CardContentWrapper)`
  flex: 1;
  padding-right: 10px;

  @media screen and (max-width: 768px) {
    background-color: ${colors.dark};
    color: ${colors.light};
  }
`
const CardRight = styled.div`
  position: relative;
  flex: 0 1 auto;
  width: 520px;

  @media screen and (max-width: 768px) {
    align-self: center;
    width: 380px;
    max-height: 457px;
    margin-bottom: -183px;
    overflow: hidden;
    transform: scale(0.6);
    transform-origin: center top;
  }
`
const CardRightWrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  padding-right: 170px;
  margin-left: 50px;

  @media screen and (max-width: 768px) {
    position: static;
    padding: 0;
    margin: 0;
  }
`

const FakeCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 360px;
  margin-bottom: 70px;
  padding: 16px;
  padding-top: 20px;

  background: ${colors.white};
  color: ${colors.lightpurple};
  border-radius: ${borderRadius};

  transform: translateY(${p => (p.isActivated ? -300 : -60)}px);
  transition: transform 1.5s cubic-bezier(0.86, 0, 0.07, 1);

  @media screen and (max-width: 768px) {
    transform: translateY(${p => (p.isActivated ? -255 : -90)}px);
    margin: 0 auto 50px;
    max-width: 250px;
    height: 290px;
  }

  &:nth-of-type(2) {
    transition-delay: 0.5s;
  }
  &:nth-of-type(3) {
    transition-delay: 1s;
  }
`

const FakeCardTitle = styled(typographyComponents.h5)`
  display: flex;
  flex-direction: column;
  font-family: ${makeFontFamily("body")};
  font-weight: 600;
`
const FakeCardButtonsWrapper = styled.div`
  flex-basis: 48%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;

  & > * {
    margin-bottom: 4px;
  }
`

const FakeCardButton = styled.button`
  padding: 10px 16px;
  text-align: left;
  text-transform: initial;
  font-family: ${makeFontFamily("body")};
  font-size: 16px;
  font-weight: 500;
  background: ${colors.lightpurple};
  color: ${colors.white};
  border-radius: ${borderRadius};
  border: none;
  cursor: pointer;
`
const FakeCardGuideButton = styled.button`
  align-self: flex-start;
  padding: 10px 20px;
  text-transform: initial;
  background: transparent;
  font-family: ${makeFontFamily("body")};
  font-size: 16px;
  font-weight: 500;
  border: 2px solid currentColor;
  border-radius: 100px;
  color: inherit;
  cursor: pointer;
`

const FakeCard = ({ isActivated }) => (
  <FakeCardWrapper isActivated={isActivated}>
    <FakeCardTitle>
      <span style={{ color: colors.primary }}>Try this solution</span>
      <span>Clean the drain filter</span>
    </FakeCardTitle>
    <FakeCardGuideButton>Step-by-step guide</FakeCardGuideButton>
    <FakeCardButtonsWrapper>
      <FakeCardButton>Problem solved</FakeCardButton>
      <FakeCardButton style={{ opacity: 0.5 }}>
        Problem is still there
      </FakeCardButton>
    </FakeCardButtonsWrapper>
  </FakeCardWrapper>
)

const FakeSystem = () => {
  const [isActivated, setIsActivated] = React.useState(false)
  const wrapperRef = useScrollTrigger(() => {
    setIsActivated(true)
  })
  return (
    <CardRightWrapper aria-hidden ref={wrapperRef}>
      <FakeCardWrapper isActivated={isActivated} />
      <FakeCard isActivated={isActivated} />
      <FakeCardWrapper isActivated={isActivated} />
    </CardRightWrapper>
  )
}

const CardTroubleshoot = () => {
  React.useEffect(() => {
    installMavenoidTS()
  }, [])
  return (
    <Wrapper variant="lightpurple">
      <CardLeft>
        <CardTitle maxWidth={380} variant="h2" as="h3">
          Smarter self-service
        </CardTitle>

        <CardCaption maxWidth={295}>
          <CopyTitle as="h4">Automated troubleshooting that works</CopyTitle>

          <CopyP as="p">
            Take care of tough problems that chatbots and help centers can’t
            handle.
          </CopyP>

          <CopyTitle as="h4">Put any support flow on autopilot</CopyTitle>

          <CopyP as="p">
            FAQs, usage instructions, problem-solving and ordering of spare
            parts. All automated so more customers can help themselves.
          </CopyP>

          <CopyTitle as="h4">Built for humans</CopyTitle>

          <CopyP as="p">
            Describe questions with text or speech. Narrow down possible
            solutions fast with simple diagnostic questions.
          </CopyP>
        </CardCaption>

        <Button
          onClick={() => {
            window.mavenoid.push({
              productId: embeddedConfig.productId,
              showWelcome: true,
            })
            trackEvent({
              category: "view_item",
              label: "embedded troubleshooter opened",
              siteSection: "product_troubleshooter_card",
            })
          }}
          style={{ marginTop: 16 }}
        >
          Try self-service
        </Button>
      </CardLeft>
      <CardRight>
        <FakeSystem />
      </CardRight>
    </Wrapper>
  )
}

export default CardTroubleshoot
