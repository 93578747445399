// @ts-check
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import colors from "../../styles/colors"
import {
  CardCaption,
  CardContentWrapper,
  CardTitle,
  CardWrapper,
} from "../card"
import { typographyComponents } from "../typography"
import Button from "../button"
import Link from "../link"

const Wrapper = styled(CardWrapper)`
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const CopyTitle = styled(typographyComponents.caption)`
  color: ${colors.primary};
`
const CopyP = styled(typographyComponents.caption)`
  margin-top: 0;
  margin-bottom: 20px;
`

const CardLeft = styled(CardContentWrapper)`
  flex: 1;
  padding-right: 10px;

  @media screen and (max-width: 768px) {
    background-color: ${colors.dark};
    color: ${colors.light};
  }
`
const CardRight = styled.div`
  position: relative;
  flex: 0 1 auto;
  width: 520px;
  display: flex;
  align-items: stretch;
  padding-right: 120px;
  padding-top: 30px;

  @media screen and (max-width: 768px) {
    align-self: center;
    width: 381px;
    max-height: 626px;
    margin-bottom: -249px;
    padding-right: 0;
    overflow: hidden;
    transform: scale(0.6);
    transform-origin: center top;
  }
`

const CardProductLiveSupport = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "product/landing-product-livechat.png" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 1240
            quality: 80
            traceSVG: { background: "#5C477E", color: "#232A32" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Wrapper variant="lightpurple">
      <CardLeft>
        <CardTitle maxWidth={500} variant="h2" as="h3">
          Speedier live support
        </CardTitle>

        <CardCaption maxWidth={360}>
          <CopyTitle as="h4">
            Accessible for all—no apps, no downloads.
          </CopyTitle>

          <CopyP as="p">
            Take customers seamlessly from self-service to live video or chat.
            It’s all in the browser and starts with a single click.
          </CopyP>

          <CopyTitle as="h4">Less friction, faster solutions</CopyTitle>

          <CopyP as="p">
            Type or talk. Send images and files, draw on the screen, take
            screenshots. Live support has never been more intuitive.
          </CopyP>

          <CopyTitle as="h4">
            Real-time decision support for your agents
          </CopyTitle>

          <CopyP as="p">
            Mavenoid suggests the most probable solutions and learns through
            feedback loops.
          </CopyP>
        </CardCaption>

        <Button as={Link} to="/features/video">
          Explore Live Support
        </Button>
      </CardLeft>
      <CardRight>
        <Img
          fluid={image.childImageSharp.fluid}
          objectFit="cover"
          alt="A conversation between a live support agent and a customer on Mavenoid"
          style={{ width: "100%" }}
        />
      </CardRight>
    </Wrapper>
  )
}

export default CardProductLiveSupport
