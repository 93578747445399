import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "@emotion/styled"

import CardBase, { CardTitle, CardCaption } from "../card"
import { borderRadius } from "../../styles/constants"
import colors from "../../styles/colors"

const RoundImage = styled(Img)`
  /* Small desktop improvement */
  height: 100%;

  /* Safari fix */
  img {
    border-radius: ${borderRadius};
  }

  /* Aspect-ratio fix - this should be handled by gatsby-image but isn't */
  @media (max-width: 768px) {
    & > div {
      padding-bottom: 82% !important;
    }
  }
`

const ImageCardProductHero = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "product/landing-product.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1240
            quality: 80
            traceSVG: { background: "#DDDDDE", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      mobile: file(relativePath: { eq: "product/landing-product-mobile.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 1200
            quality: 80
            traceSVG: { background: "#DDDDDD", color: "#232322" }
          ) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <RoundImage
      fluid={[
        data.mobile.childImageSharp.fluid,
        { ...data.desktop.childImageSharp.fluid, media: "(min-width: 769px)" },
      ]}
      objectFit="cover"
      objectPosition="50% 50%"
      alt="Mavenoid Troubleshooter in front of a quadri-copter"
    />
  )
}

const Card = styled(CardBase)`
  color: ${colors.lightpurple};

  @media (max-width: 768px) {
    color: ${colors.light};
  }
`

const CardProductHero = () => (
  <Card img={<ImageCardProductHero />}>
    <CardTitle variant="h1" as="h1" maxWidth={400}>
      Frictionless product support
    </CardTitle>

    <CardCaption maxWidth={330}>
      Mavenoid packs self-service and live support in one powerful toolbox.
      You’ll be able to serve more customers with less effort, and reach
      solutions faster than ever before.
    </CardCaption>
  </Card>
)

export default CardProductHero
