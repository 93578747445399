// @ts-check
import React from "react"
import styled from "@emotion/styled"

import {
  CardWrapper,
  CardContentWrapper,
  CardTitle,
  CardCaption,
} from "../card"
import { borderRadius } from "../../styles/constants"
import colors from "../../styles/colors"
import Typography, { typographyComponents } from "../typography"
import useScrollTrigger from "../../hooks/useScrollTrigger"

const Wrapper = styled(CardWrapper)`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`

const CopyTitle = styled(typographyComponents.caption)`
  color: ${colors.primary};
`
const CopyP = styled(typographyComponents.caption)`
  margin-top: 0;
  margin-bottom: 20px;
`

const CardText = styled(CardContentWrapper)`
  max-width: 600px;
  @media (min-width: 769px) {
    padding-right: 20px;
  }
`
const CardIllustration = styled(CardContentWrapper)`
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  padding-left: 0;

  @media screen and (max-width: 768px) {
    flex: auto;
    height: 290px;
    padding-bottom: 0;
  }
`

const AnalyticsCard = styled.div`
  position: relative;
  height: 260px;
  width: 260px;
  padding: 18px;
  padding-right: 100px;
  border-radius: ${borderRadius};
  background: ${colors.light};
  color: ${colors.dark};

  @media screen and (max-width: 768px) {
    height: 170px;
    width: 150px;
    margin: 0;
    padding: 10px;
  }
`

const AnalyticsCardIssues = styled(AnalyticsCard)`
  align-self: flex-start;
  margin-right: 60px;
`

const AnalyticsCardResolutions = styled(AnalyticsCard)`
  align-self: flex-end;
`

const IssuesBarWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: row-reverse;
`

const issueBarTransitionTime = 1.3
const IssueBar = styled.div`
  width: 44px;
  margin-left: 24px;
  background: ${colors.primary};
  height: 118px;
  transform-origin: bottom center;
  transition: transform ${issueBarTransitionTime}s cubic-bezier(0.5, 0, 0, 1);

  @media screen and (max-width: 768px) {
    width: 18px;
    margin-left: 10px;
    height: 70px;
  }
`

const Bar = ({ initialScale }) => {
  const [scale, setScale] = React.useState(initialScale)
  React.useEffect(() => {
    const interval = setInterval(
      () => setScale(Math.random()),
      issueBarTransitionTime * 1000 * 0.9
    )
    return () => clearInterval(interval)
  }, [])
  return <IssueBar style={{ transform: `scaleY(${scale})` }} />
}

const ResolutionCurveWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  @media screen and (max-width: 768px) {
    transform: scale(0.55);
    transform-origin: bottom right;
  }
`
const ResolutionCurveSVG = styled.svg`
  width: 480px;
  height: 160px;
`

const CardAnalytics = () => {
  const ref = useScrollTrigger(() => {
    if (!ref.current) return

    // @ts-expect-error the ref is a SVG element
    ref.current.getElementsByTagName("animate")[0].beginElement()
  })
  return (
    <Wrapper>
      <CardText>
        <CardTitle maxWidth={320} variant="h2" as="h3">
          Gain new insights
        </CardTitle>

        <CardCaption maxWidth={300}>
          <CopyTitle as="h4">Deeper insights</CopyTitle>

          <CopyP as="p">
            Uncover the root causes of your support requests, and target your
            most pressing issues.
          </CopyP>

          <CopyTitle as="h4">Custom metrics</CopyTitle>

          <CopyP as="p">
            Track resolution rate, customer satisfaction, and more to understand
            how you’re doing.
          </CopyP>

          <CopyTitle as="h4">Fast bug detection</CopyTitle>

          <CopyP as="p">
            Detect and act quickly on production errors or failed updates.
          </CopyP>
        </CardCaption>
      </CardText>
      <CardIllustration aria-hidden>
        <AnalyticsCardIssues>
          <Typography variant="bodyLarge">Most common issues</Typography>
          <IssuesBarWrapper>
            <Bar initialScale={1} />
            <Bar initialScale={0.7} />
            <Bar initialScale={0.6} />
            <Bar initialScale={0.6} />
            <Bar initialScale={0.3} />
          </IssuesBarWrapper>
        </AnalyticsCardIssues>
        <AnalyticsCardResolutions>
          <Typography variant="bodyLarge">Number of resolutions</Typography>

          <ResolutionCurveWrapper>
            <ResolutionCurveSVG viewBox="0 0 496 193" ref={ref}>
              <path
                d="M7 162.5c44.5-26 116.5-51.5 192 0s129.5-24 151.5-53.5 76-90.5 144-96"
                stroke={colors.primary}
                strokeWidth="25"
                strokeDasharray="565 565"
                strokeDashoffset="565"
                fill="none"
              >
                <animate
                  attributeName="stroke-dashoffset"
                  from="565"
                  to="0"
                  dur="1s"
                  fill="freeze"
                  begin="indefinite"
                />
              </path>
            </ResolutionCurveSVG>
          </ResolutionCurveWrapper>
        </AnalyticsCardResolutions>
      </CardIllustration>
    </Wrapper>
  )
}

export default CardAnalytics
