import React from "react"
import styled from "@emotion/styled"

import {
  CardWrapper,
  CardContentWrapper,
  CardTitle,
  CardCaption,
} from "../card"
import { typographyComponents } from "../typography"
import colors from "../../styles/colors"
import useScrollTrigger from "../../hooks/useScrollTrigger"

const Text = styled(typographyComponents.bodyLarge)`
  display: block;
  color: ${colors.dark};
  text-align: center;
`

const Svg = styled.svg`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;

  @media screen and (max-width: 1200px) {
    transform: scale(1.1);
    transform-origin: left;
  }

  @media screen and (max-width: 900px) {
    position: initial;
    transform: scale(1.2) translateX(40px);
    transform-origin: right;
    margin-bottom: -20px;
  }

  @media screen and (max-width: 450px) {
    margin: 60px 0 30px;
    transform: scale(2) translateX(40px);
  }
`

const shiftX = 200
const animationDuration = 2

// @TODO i18n - this will be tricky to do properly because of multiline text
const ModelsGraph = () => {
  const ref = useScrollTrigger(() => {
    Array.from(ref.current.getElementsByTagName("animate")).forEach(e =>
      e.beginElement()
    )
  })
  return (
    <Svg
      fill="none"
      viewBox={`${-shiftX} 0 ${1222.5 + shiftX} 494`}
      aria-hidden
      ref={ref}
    >
      {/* 1st case */}
      <path
        d="M455 238a4 4 0 0 1 4-4h210a4 4 0 0 1 4 4v91a4 4 0 0 1-4 4H459a4 4 0 0 1-4-4z"
        fill={colors.light}
      />
      <g fill={colors.primary} stroke={colors.dark} strokeWidth="3">
        <circle cx="455.2" cy="282.8" r="8" />
        <circle cx="673.2" cy="282.8" r="8" opacity="0">
          <animate
            attributeName="opacity"
            values="0;0;1;1"
            keyTimes="0;0.1;0.11;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </circle>
      </g>
      <foreignObject x="474" y="258" width="181" height="60">
        <Text>Water stuck inside the machine</Text>
      </foreignObject>

      {/* 2nd case */}
      <path
        d="M812 68a4 4 0 0 1 4-4h150a4 4 0 0 1 4 4v91a4 4 0 0 1-4 4H816a4 4 0 0 1-4-4z"
        fill={colors.light}
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.01;0.1;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </path>
      <g fill={colors.light} stroke={colors.dark} strokeWidth="3">
        <circle cx="812.2" cy="112.8" r="8" opacity="0">
          <animate
            attributeName="opacity"
            values="0;0;1;1"
            keyTimes="0;0.14;0.20;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </circle>
        <circle cx="970.2" cy="112.8" r="8" opacity="0">
          <animate
            attributeName="opacity"
            values="0;0;1;1"
            keyTimes="0;0.7;0.8;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </circle>
      </g>
      <foreignObject y="86" x="829" width="125" height="60">
        <Text>Drain hose blocked</Text>
      </foreignObject>

      {/* 3rd case */}
      <path
        d="M812 197a4 4 0 0 1 4-4h184a4 4 0 0 1 4 4v91a4 4 0 0 1-4 4H816a4 4 0 0 1-4-4z"
        fill={colors.primary}
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.40;0.50;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </path>
      <circle
        cx="812.2"
        cy="242"
        r="8"
        fill={colors.primary}
        stroke={colors.dark}
        strokeWidth="3"
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.62;0.70;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </circle>
      <foreignObject x="828" y="215" width="165" height="60">
        <Text>Drain hose still in the machine</Text>
      </foreignObject>

      {/* 4th case */}
      <path
        d="M812 398a4 4 0 0 1 4-4h178a4 4 0 0 1 4 4v91a4 4 0 0 1-4 4H816a4 4 0 0 1-4-4z"
        fill={colors.light}
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.15;0.25;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </path>
      <circle
        cx="812.2"
        cy="443.8"
        r="8"
        fill={colors.light}
        stroke={colors.dark}
        strokeWidth="3"
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.30;0.39;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </circle>
      <foreignObject x="831" y="417" width="147" height="60">
        <Text>Clogged machine filter</Text>
      </foreignObject>
      <circle
        cx="998.2"
        cy="443.8"
        r="8"
        fill={colors.light}
        stroke={colors.dark}
        strokeWidth="3"
        opacity="0"
      >
        <animate
          attributeName="opacity"
          values="0;0;1;1"
          keyTimes="0;0.8;0.89;1"
          begin="indefinite"
          fill="freeze"
          dur={animationDuration}
        />
      </circle>

      {/* 
          all strokes, ordered from left to right and top to bottom (except the color.primary one
          which is after the number 2 and 4)
      */}
      <g stroke={colors.light} strokeWidth="2">
        <path
          d={`M${-shiftX} 143c351.5 0 317 140 ${443.5 + shiftX} 140`}
          stroke={colors.primary}
        />
        <path
          d="M802 113c-72.8 0-54.5 170-119 170"
          strokeDasharray="223 223"
          strokeDashoffset="223"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="-223;-223;0;0"
            keyTimes="0;0.10;0.19;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
        <path
          d="M802 443c-72.8 0-54.5-160-119-160"
          strokeDasharray="214 214"
          strokeDashoffset="214"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="-214;-214;0;0"
            keyTimes="0;0.30;0.39;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
        <path
          stroke={colors.primary}
          d="M802 243c-59 0-48 40-119 40"
          strokeDasharray="129 129"
          strokeDashoffset="129"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="-129;-129;0;0"
            keyTimes="0;0.60;0.69;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
        <path
          d="M980 114c116.9 0 138-112 241.5-112"
          strokeDasharray="274 274"
          strokeDashoffset="274"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="274;274;0;0"
            keyTimes="0;0.75;0.95;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
        <path
          d="M980 114c116.9 0 138-39 241.5-39"
          strokeDasharray="246 246"
          strokeDashoffset="246"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="246;246;0;0"
            keyTimes="0;0.78;0.98;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
        <path
          d="M1008 443c73.6 0 91 41 230.5 41"
          strokeDasharray="236 236"
          strokeDashoffset="236"
        >
          <animate
            attributeName="stroke-dashoffset"
            values="236;236;0;0"
            keyTimes="0;0.8;0.99;1"
            begin="indefinite"
            fill="freeze"
            dur={animationDuration}
          />
        </path>
      </g>
    </Svg>
  )
}

const ContentWrapper = styled(CardContentWrapper)`
  max-width: 600px;
  margin: 0;
`
const CopyTitle = styled(typographyComponents.caption)`
  color: ${colors.primary};
`
const CopyP = styled(typographyComponents.caption)`
  margin-top: 0;
  margin-bottom: 20px;
`

const CardModels = () => (
  <CardWrapper>
    <ModelsGraph />
    <ContentWrapper>
      <CardTitle maxWidth={350} variant="h2" as="h3">
        Bring all your knowledge to life
      </CardTitle>

      <CardCaption maxWidth={350}>
        <CopyTitle as="h4">Teachable AI</CopyTitle>
        <CopyP as="p">
          By combining human knowledge with machine learning, you can set up and
          launch your virtual expert in days, not months.
        </CopyP>

        <CopyTitle as="h4">Continuous improvement</CopyTitle>

        <CopyP as="p">
          Learn from each and every interaction, and step in at any time to
          update your virtual expert with common sense.
        </CopyP>

        <CopyTitle as="h4">Everything in one place</CopyTitle>

        <CopyP as="p">
          Organize know-how spread out across manuals, tickets, websites, wikis,
          FAQs, and the minds of your workforce, under one single model.
        </CopyP>
      </CardCaption>
    </ContentWrapper>
  </CardWrapper>
)

export default CardModels
